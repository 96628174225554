import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { customStyles } from "../tableStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faFileCsv, faFileExcel, faFilePdf, faPrint, faTimes } from "@fortawesome/free-solid-svg-icons";


const AddEditOrganizationForm = () => {
  const { id } = useParams(); // Get organization ID from URL
  const navigate = useNavigate();

  //const isEditMode = Boolean(id); // Determine if the form is in edit mode
  const [isEditMode, setIsEditMode] = useState(Boolean(id));

  // State for form data
  const [formData, setFormData] = useState({
    org_type_id: "",
    name: "",
    short_name: "",
    status: "active",
    founding_year: "",
    aff_year: "",
    website: "",
    comments: "",
    web_desc: "",
    phone1: "",
    phone2: "",
    email: "",
    email2: "",
    address1: "",
    address2: "",
    fax: "",
    country_id: "",
    city: "",
    state: "",
    zipcode: "",
    continent: "",
    active:"",
  });

  const [logoPreview, setLogoPreview] = useState("/images/logo.png");
  const [countries, setCountries] = useState([]);
  const [orgTypes, setOrgTypes] = useState([]); // Organization types
  const [activeTab, setActiveTab] = useState(isEditMode? "Members":"Profile");
  const [members, setMembers] = useState([]);
  const [pastMembers, setPastMembers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [dialog, setDialog] = useState({ type: "", message: "", isVisible: false });

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => 1900 + i);
  

  // Fetch organization data, countries, members, past members, and org types
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);
        setCountries(countriesResponse.data);

        const orgTypesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/org-types`);
        setOrgTypes(orgTypesResponse.data);

        if (isEditMode) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/organisations/${id}`);
          const organisation = response.data;
          console.log(organisation);

          setFormData({
            org_type_id: organisation.org_type_id._id || "",
            name: organisation.name || "",
            short_name: organisation.short_name || "",
            status: organisation.status || "",
            active: organisation.active || false,
            founding_year: organisation.founding_year || "",
            aff_year: organisation.aff_year || "",
            website: organisation.website || "",
            comments: organisation.comments || "",
            web_desc: organisation.web_desc || "",
            phone1: organisation.phone1 || "",
            phone2: organisation.phone2 || "",
            email: organisation.email || "",
            email2: organisation.email2 || "",
            address1: organisation.address1 || "",
            address2: organisation.address2 || "",
            fax: organisation.fax || "",
            country_id: organisation.country_id?._id || "",
            city: organisation.city || "",
            state: organisation.state || "",
            zipcode: organisation.zipcode || "",
            continent: organisation.continent || "",
          });          
     
          setLogoPreview(organisation.logo ? `/images/${organisation.logo}` : "/images/logo.png");
          fetchMembers(id);          
          setPastMembers(organisation.past_members || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, isEditMode]);

  const fetchMembers = async (orgId) => {
    setLoadingMembers(true);
    try {
      const membersRes = await axios.get(`${process.env.REACT_APP_API_URL}/organization/${orgId}/members`);
      setMembers(membersRes.data);
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      setLoadingMembers(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prev) => ({
      ...prev,
      [name]:
        type === "checkbox" || type === "radio" // Handle boolean values
          ? checked
          : type === "number" // Convert numeric inputs
          ? Number(value)
          : value, // Default case for text, select, etc.
    }));
  };

  // Handle logo file selection
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => setLogoPreview(reader.result);
    reader.readAsDataURL(file);
  };

  const handleEditorChange = (content) => {
    setFormData((prev) => ({
      ...prev,
      web_desc: content, // Bind editor content to web_desc
    }));
  };

  const handleCloseDialog = () => {
    setDialog({ type: "", message: "", isVisible: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Create a FormData object
      const uploadFormData = new FormData();
  
      // Append all form fields to the FormData object
      for (const [key, value] of Object.entries(formData)) {
        uploadFormData.append(key, value);
      }
  
      // Append the file with the key "logo" as expected by the backend route
      if (selectedFile) {
        uploadFormData.append("logo", selectedFile);
      }
  
      let response;
  
      if (isEditMode) {
        // Update the organization using PUT method
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/organisations/${id}`,
          uploadFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      } else {
        // Create a new organization using POST method
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/organizations`,
          uploadFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
  
        // Extract the new organization data
        const newOrganization = response.data.organization;
        setFormData(newOrganization); // Update formData state with the new organization data
        setIsEditMode(true);
        setDialog({ type: "success", message: "Organization added successfully!", isVisible: true });
  
        // Update the URL to include the new organization's ID (optional)
        window.history.pushState({}, "", `/organisations/${newOrganization._id}`);
      }
  
      // Show success dialog
      setDialog({ type: "success", message: "Organization saved successfully!", isVisible: true });
    } catch (error) {
      console.error("Error saving organization:", error);
      setDialog({ type: "failed", message: "Failed to save organization. Please try again.", isVisible: true });
    }
  };

  // Render Profile Tab
  const renderProfileTab = () => (
    <div>
      <div className="row my-5">
        <div className="form-group col-md-3">
          <label>Organization Type</label>
          <select className="form-control py-3" name="org_type_id" value={formData.org_type_id} onChange={handleInputChange}>
            <option value="">Select Organization Type</option>
            {orgTypes.map((type) => (
              <option key={type._id} value={type._id}>
                {type.org_type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-3">
          <label>Name</label>
          <input type="text" className="form-control py-3" name="name" value={formData.name} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>Short Name</label>
          <input type="text" className="form-control py-3" name="short_name" value={formData.short_name} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>Status</label>
          <div>
            <label className="me-2">
              <input type="radio" name="active" value="true" checked={formData.active === true} onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            active: true, // Convert string to boolean
          }))
        } />
              Active
            </label>
            <label>
              <input type="radio" name="active" value="false" checked={formData.active === false} onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            active: false, // Convert string to boolean
          }))
        } />
              Inactive
            </label>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col-md-3">
          <label>Year Founded</label>       
        <select
          id="foundingYearDropdown"
          name="founding_year"
          value={formData.founding_year}
          onChange={handleInputChange}
          className="form-control py-3"
        >
          <option value="">-- Select Founding Year --</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        </div>
        <div className="form-group col-md-3">
          <label>Year IGF Affiliation</label>
          <select
          id="affYearDropdown"
          name="aff_year"
          value={formData.aff_year}
          onChange={handleInputChange}
          className="form-control py-3"
        >
          <option value="">-- Select Affiliation Year --</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        </div>
        <div className="form-group col-md-3">
          <label>Website</label>
          <input type="text" className="form-control py-3" name="website" value={formData.website} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>Comments</label>
          <textarea className="form-control py-3" name="comments" value={formData.comments} onChange={handleInputChange} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col-md-12">
          <label>Website Description</label>
          <Editor
            apiKey="zk2zzdzsc5ofia3cqt9boi8numoj5fr2y4lj21p6lstv9t9t"
            value={formData.web_desc}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleEditorChange}
          />       
        </div>
      </div>
    </div>
  );

  // Render Address Tab
  const renderAddressTab = () => (
    <div>
      <div className="row my-5">
        <div className="form-group col-md-3">
          <label>Landline</label>
          <input type="text" className="form-control py-3" name="phone1" value={formData.phone1} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>Mobile Phone</label>
          <input type="text" className="form-control py-3" name="phone2" value={formData.phone2} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>Email 1</label>
          <input type="email" className="form-control py-3" name="email" value={formData.email} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>Email 2</label>
          <input type="email" className="form-control py-3" name="email2" value={formData.email2} onChange={handleInputChange} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col-md-3">
          <label>Address Line 1</label>
          <input type="text" className="form-control py-3" name="address1" value={formData.address1} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>City</label>
          <input type="text" className="form-control py-3" name="city" value={formData.city} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>State</label>
          <input type="text" className="form-control py-3" name="state" value={formData.state} onChange={handleInputChange} />
        </div>
        <div className="form-group col-md-3">
          <label>Country</label>
          <select className="form-control py-3" name="country_id" value={formData.country_id} onChange={handleInputChange}>
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country._id} value={country._id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  // Render Members Tab  

  const renderMembersTab = () => {


    const exportToExcel = () => {
      const ws = XLSX.utils.json_to_sheet(members);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Members");
      XLSX.writeFile(wb, "members.xlsx");
    };
  
    const exportToPdf = () => {
      const doc = new jsPDF();
      const tableColumn = columns.map((col) => col.name);
      const tableRows = members.map((row, index) => [
        index + 1,        
        row.person_name,
        row.email,
        row.role_name,
        row.assistant_name || "No Assistant",
        row.start_date
          ? new Date(row.start_date).toLocaleDateString()
          : "N/A",
        row.end_date ? new Date(row.end_date).toLocaleDateString() : "N/A",
        row.main_role ? "Yes" : "No",
      ]);
  
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
      });
      doc.save("members.pdf");
      setDialog({ type: "members", message: "PDF Generated!", isVisible: true }); 
    };
  
    const copyToClipboard = () => {
      const textData = members
        .map((row) =>
          columns.map((col) => (typeof col.selector === "function" ? col.selector(row) : row[col.name])).join("\t")
        )
        .join("\n");
      navigator.clipboard.writeText(textData);
      setDialog({ type: "members", message: "Copied to clipboard!", isVisible: true });      
    };
  
    const exportToCsv = () => {
      const csvContent =
        columns.map((col) => col.name).join(",") +
        "\n" +
        members
          .map((row) =>
            columns.map((col) => (typeof col.selector === "function" ? col.selector(row) : row[col.name])).join(",")
          )
          .join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "members.csv");
      setDialog({ type: "members", message: "Csv Generated!", isVisible: true }); 
    };
  
    const printTable = () => {
      const printWindow = window.open("", "", "width=800,height=600");
      const printContent = `
        <table border="1" style="width:100%;border-collapse:collapse;">
          <thead>
            <tr>${columns.map((col) => `<th>${col.name}</th>`).join("")}</tr>
          </thead>
          <tbody>
            ${members
              .map(
                (row) =>
                  `<tr>${columns
                    .map((col) =>
                      typeof col.selector === "function"
                        ? `<td>${col.selector(row)}</td>`
                        : `<td>${row[col.name]}</td>`
                    )
                    .join("")}</tr>`
              )
              .join("")}
          </tbody>
        </table>
      `;
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.print();
    };
  
    const handleRowClick = (row) => {
      navigate(`/edit-person/${row.person_id}`, { state: { person: row } }); // Use _id
    };
    // Columns for DataTable
    const columns = [
      {
        name: "#",
        cell: (row, index) => index + 1,
        width: "60px",
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: "Photo",
        cell: (row) => (
          <img
            src={row.image ? `/images/${row.image}` : "/images/avatar.png"}
            alt={row.person_name}
            width="50"
            className="rounded-circle"
          />
        ),
        width: "100px",
        ignoreRowClick: true,
        allowOverflow: true,
        sortable: false,
      },
      {
        name: 'Name',
        selector: (row) => row.person_name,
        sortable: true,
        cell: (row) => (
          <span className='text-warning'
            style={{cursor: 'pointer' }}
            onClick={() => handleRowClick(row)}
          >
            {row.person_name}
          </span>
        ),
      },
      { name: "Person Name", selector: (row) => row.person_name, sortable: true },
      { name: "Email", selector: (row) => row.email, sortable: true },
      { name: "Role", selector: (row) => row.role_name, sortable: true },
      {
        name: "Assistant",
        selector: (row) => row.assistant_name || "No Assistant",
        sortable: true,
      },
      {
        name: "Start Date",
        selector: (row) =>
          row.start_date ? new Date(row.start_date).toLocaleDateString() : "N/A",
        sortable: true,
      },
      {
        name: "End Date",
        selector: (row) =>
          row.end_date ? new Date(row.end_date).toLocaleDateString() : "N/A",
        sortable: true,
      },
      {
        name: "Main Role",
        selector: (row) => (row.main_role ? "Yes" : "No"),
        sortable: true,
      },
    ];

    const data = {
      columns,
      data: members,
    };
  
    return (
      <div className="py-5">       
        {loadingMembers ? (
          <div>Loading members...</div>
        ) : members.length > 0 ? (
          <div className="mt-2">
            <button className="btn btn-primary mx-1 mb-5 py-2" onClick={copyToClipboard}>
  <FontAwesomeIcon icon={faCopy} type="button" className="me-2" />
  Copy
</button>
<button className="btn btn-info mx-1 mb-5 py-2" type="button" onClick={exportToCsv}>
  <FontAwesomeIcon icon={faFileCsv} className="me-2" />
  Export to CSV
</button>
<button className="btn btn-dark mx-1 mb-5 py-2" type="button" onClick={exportToExcel}>
  <FontAwesomeIcon icon={faFileExcel} className="me-2" />
  Export to Excel
</button>
<button className="btn btn-danger mx-1 mb-5 py-2" type="button" onClick={exportToPdf}>
  <FontAwesomeIcon icon={faFilePdf} className="me-2" />
  Export to PDF
</button>
<button className="btn btn-secondary mx-1 mb-5 py-2" type="button" onClick={printTable}>
  <FontAwesomeIcon icon={faPrint} className="me-2" />
  Print
</button>
          {/* <DataTableExtensions {...data}> */}
            <DataTable
              columns={columns}
              data={members}
              pagination
              highlightOnHover
              striped
              responsive
              customStyles={customStyles}
              onRowClicked={handleRowClick}
            />
            {/* </DataTableExtensions> */}
          </div>
        ) : (
          <div>No members found for this organization.</div>
        )}
      </div>
    );
  };

  // Render Past Members Tab
  const renderPastMembersTab = () => (
    <div className="py-5">
      <h4>Past Members</h4>
      {pastMembers.map((member, index) => (
        <div key={index} className="mb-2">
          {member.name}
        </div>
      ))}
    </div>
  );

  return (
    <div className="mt-4">
      <div className="row mb-4">
        <div className="col-md-2 col-sm-3">
          <img src={logoPreview} alt="Logo" width="120" />
          <div>
            <button type="button" className="btn btn-secondary mt-2" onClick={() => document.getElementById("logoInput").click()}>
              Upload Logo
            </button>
            <input type="file" id="logoInput" style={{ display: "none" }} accept="image/*" onChange={handleLogoUpload} />
          </div>
        </div>
        
        <div className="col-md-10 col-sm-9 d-flex align-self-center justify-content-center">
          <h1>{isEditMode ? `${formData.name}` : "Create Organization"}</h1>
        </div>
      </div>
      <div className="row border border-white p-3 rounded"> 
      <div className="tabs mb-5">
        {["Profile", "Address", ...(isEditMode ? ["Members", "Past Members"] : [])].map((tab) => (
          <button
            key={tab}
            className={`btn py-3 ${activeTab === tab ? "btn-warning" : "btn-success"} mx-2`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <form onSubmit={handleSubmit}>
        {activeTab === "Profile" && renderProfileTab()}
        {activeTab === "Address" && renderAddressTab()}
        {activeTab === "Members" && renderMembersTab()}
        {activeTab === "Past Members" && renderPastMembersTab()}

        <button type="submit" className="btn btn-success mt-4 px-3 py-3">
          Save
        </button>
        <button type="button" className="btn btn-secondary mt-4 ms-2 px-5 py-3" onClick={() => navigate("/")}>
          Cancel
        </button>
      </form>
      {dialog.isVisible && (
        <div className="dialog-overlay">
        <div className={`dialog-box ${dialog.type}`}>
        <button className="dialog-close-button" onClick={handleCloseDialog}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
          <h4 className={`heading ${dialog.type}`}>{dialog.type}</h4>          
          <p>{dialog.message}</p>         
        </div>
      </div>        
      )}
    </div>
    </div>
  );
};

export default AddEditOrganizationForm;
