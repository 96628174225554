import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { customStyles } from '../tableStyles';
import axios from 'axios';

function OrganisationList() {
  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  // const [orgTypes, setOrgTypes] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch organisation and orgType data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch organisations with org_type_id === '3'
        const orgResponse = await axios.get(`${process.env.REACT_APP_API_URL}/organisations`);
        const filteredOrganisations = orgResponse.data.filter(org => org.org_type_id.org_type === 'Committee');

        // Fetch all organization types
        // const orgTypeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/org-types`);
        
        // // Map organization types by their ID for easy lookup
        // const orgTypesMap = orgTypeResponse.data.reduce((map, type) => {
        //   map[type.org_type_id] = type.org_type;
        //   return map;
        // }, {});

        setOrganisations(filteredOrganisations);
        //setOrgTypes(orgTypesMap);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter organisations based on search term
  const filteredOrganisations = organisations.filter(org =>
    org.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Define columns for DataTable
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },    
    {
      name: 'Logo',
      selector: row => row.logo,
      cell: row => (
        <img
          src={row.logo ? `/images/${row.logo}` : '/images/org_logo1.png'}
          alt={row.name}
          style={{ width: '50px', height: '50px', borderRadius: '4px' }}
        />
      ),
      width: '120px',
      ignoreRowClick: true,
      allowOverflow: true,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => navigate(`/organisation/${row._id}`, { state: { organisation: row } })}
        >
          {row.name}
        </button>
      ),
      sortable: true,
    },
    { 
      name: 'Org. Type', 
      selector: row => row.org_type_id.org_type || 'Unknown', 
      sortable: true 
    },  
    { 
      name: 'Email', 
      selector: row => row.email  || 'Unknown', 
      sortable: true 
    },    
    { name: 'Active', selector: row => row.active ? 'Yes' : 'No', sortable: true },
  ];

  return (
    <div className="mt-5">
      <h2>Committees</h2>

      {/* Search input */}
      <input
        type="text"
        placeholder="Search by name"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
      />

      <DataTable
        columns={columns}
        data={filteredOrganisations}
        progressPending={loading}
        pagination
        highlightOnHover
        striped
        responsive
        customStyles={customStyles} // Apply custom styles
      />
    </div>
  );
}

export default OrganisationList;
