// src/components/Login.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../images/logo.png';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null); // reCAPTCHA value
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  console.log(process.env.REACT_APP_API_URL);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      navigate('/');
    } else {
      setIsLoggedIn(false);
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set reCAPTCHA value
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      setMessage('Please complete the reCAPTCHA');
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        ...formData,
        captcha: captchaValue, // Send captcha value to the backend
      });
      const { token, username } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      setMessage('Login successful');
      navigate('/');
    } catch (err) {
      setMessage('Invalid credentials');
    }
  };

  return (
    <div className="login-page bk-img">
      <div className="form-content">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center vh-100">
            <div className="col-md-6 col-md-offset-3 login-box">
              <h1 className="text-center pt-2x">
                <img src={logo} width="150" alt="Logo" />
              </h1>
              <h1 className="text-center text-bold">Admin Login</h1>
              <div className="well row pt-2x pb-3x bk-light align-items-center justify-content-center">
                <div className="col-md-8 col-md-offset-2">
                  <div id="message-wrap">
                    <span></span>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-group mb-4">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter your password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Replace with your Google reCAPTCHA site key
                      onChange={handleCaptchaChange}
                    />

                    <button type="submit" className="btn btn-warning btn-block mt-3">
                      Login
                    </button>

                    {message && (
                      <div
                        className={`alert ${
                          message === 'Login successful' ? 'alert-success' : 'alert-danger'
                        }`}
                        role="alert"
                      >
                        {message}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
