import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { customStyles } from '../tableStyles';

function PersonList() {
  const [persons, setPersons] = useState([]);
  const [filteredPersons, setFilteredPersons] = useState([]);
  const [organisations, setOrganisations] = useState({});
  const [roles, setRoles] = useState({});
  const [personRoles, setPersonRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const personResponse = await axios.get(`${process.env.REACT_APP_API_URL}/persons`);
        const orgResponse = await axios.get(`${process.env.REACT_APP_API_URL}/organisations`);
        const roleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/roles`);
        const personRoleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/personRoles`);

        const orgMap = orgResponse.data.reduce((map, org) => {
          map[org._id] = org.name; // Use _id for mapping
          return map;
        }, {});

        const roleMap = roleResponse.data.reduce((map, role) => {
          map[role._id] = role.role_name; // Use _id for mapping
          return map;
        }, {});

        setPersons(personResponse.data);
        setFilteredPersons(personResponse.data);
        setOrganisations(orgMap);
        setRoles(roleMap);
        setPersonRoles(personRoleResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getRelationships = (personId) => {
    const relations = personRoles.filter((role) => role.person_id === personId);
    return relations.map((relation) => ({
      organisation: organisations[relation.org_id] || 'Unknown',
      role: roles[relation.role_id] || 'Unknown',
    }));
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = persons.filter((person) => {
      const relationships = getRelationships(person._id); // Use _id
      const orgNames = relationships.map((r) => r.organisation.toLowerCase()).join(' ');
      const roleNames = relationships.map((r) => r.role.toLowerCase()).join(' ');
      const fullName = `${person.name} ${person.mname || ''} ${person.lname || ''}`.toLowerCase();
      return (
        fullName.includes(value) ||
        person.email.toLowerCase().includes(value) ||
        orgNames.includes(value) ||
        roleNames.includes(value)
      );
    });
    setFilteredPersons(filtered);
  };

  const handleRowClick = (row) => {
    navigate(`/edit-person/${row._id}`, { state: { person: row } }); // Use _id
  };

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/persons/${_id}`);
      setPersons((prev) => prev.filter((person) => person._id !== _id));
      setFilteredPersons((prev) => prev.filter((person) => person._id !== _id));
    } catch (error) {
      console.error('Error deleting person:', error);
    }
  };

  const openDialog = (person) => {
    setSelectedPerson(person);
    setDialogVisible(true);
  };

  const confirmDelete = () => {
    if (selectedPerson) {
      handleDelete(selectedPerson._id); // Use _id
    }
    setDialogVisible(false);
    setSelectedPerson(null);
  };

  const cancelDelete = () => {
    setDialogVisible(false);
    setSelectedPerson(null);
  };

  const columns = [
    {
      name: 'Image',
      selector: (row) => row.image,
      cell: (row) => (
        <img
          src={row.image ? `/images/${row.image}` : '/images/avatar.png'}
          alt={row.name}
          style={{ width: '50px', height: '50px', borderRadius: '4px' }}
        />
      ),
      width: '120px',
      ignoreRowClick: true,
    },
    {
      name: 'Name',
      selector: (row) => `${row.name} ${row.mname || ''} ${row.lname}`,
      sortable: true,
      cell: (row) => (
        <span className='text-warning'
          style={{cursor: 'pointer' }}
          onClick={() => handleRowClick(row)}
        >
          {row.name} {row.mname || ''} {row.lname}
        </span>
      ),
    },
    { name: 'Email', selector: (row) => row.email, sortable: true },
    {
      name: 'Organisations',
      cell: (row) => {
        const relationships = getRelationships(row._id); // Use _id
        return (
          <ul>
            {relationships.map((relation, index) => (
              <li key={index}>{relation.organisation}</li>
            ))}
          </ul>
        );
      },
    },
    {
      name: 'Roles',
      cell: (row) => {
        const relationships = getRelationships(row._id); // Use _id
        return (
          <ul>
            {relationships.map((relation, index) => (
              <li key={index}>{relation.role}</li>
            ))}
          </ul>
        );
      },
    },
    {
      name: 'Xmas Cards',
      width: '120px',
      cell: (row) =>
        row.xmas1 ? (
          <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
        ) : (
          <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
        ),
      sortable: true,
    },
    {
      name: 'CEO Xmas Cards',
      width: '120px',
      cell: (row) =>
        row.xmas2 ? (
          <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
        ) : (
          <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
        ),
      sortable: true,
    },
    {
      name: 'Delete',
      width: '120px',
      cell: (row) => (
        <button
          onClick={() => openDialog(row)}
          style={{ border: 'none', background: 'none', cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={faTrash} style={{ color: '#d9534f' }} />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  return (
    <div className="mt-2">
      <h2>Individuals</h2>
      <div className="mt-2 p-3 bg-white rounded">
        <div className="row">
          <div className="col-md-4">
            <input
              type="text"
              placeholder="Search by name, email, organisation, or role"
              className="form-control mb-3 py-3"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="row">
          <DataTable
            columns={columns}
            data={filteredPersons}
            progressPending={loading}
            pagination
            highlightOnHover
            striped
            responsive
            customStyles={customStyles}
            onRowClicked={handleRowClick}
          />
        </div>
      </div>
      {dialogVisible && (
    <div className="dialog-overlay">
      <div className="dialog-box">
        <h4>Confirm Delete</h4>
        <p>Are you sure you want to delete {selectedPerson?.name}?</p>
        <button onClick={confirmDelete} className="dialog-confirm-button">
          Confirm
        </button>
        <button onClick={cancelDelete} className="dialog-cancel-button">
          Cancel
        </button>
      </div>
    </div>
  )}
    </div>
  );
}

export default PersonList;
