// src/components/DashboardLayout.js
import React, { useState, useEffect } from 'react';
import logo from '../images/logo.png';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faPeopleRoof, faHouseMedicalFlag, faRotate, faBell, faDownload, faCakeCandles } from '@fortawesome/free-solid-svg-icons';
// import './Dashboard.css'; // Import custom styles if needed

const DashboardLayout = ({ username, handleLogout }) => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const un = localStorage.getItem('username');

     useEffect(() => {
    // Check if the token exists in localStorage
    const token = localStorage.getItem('token');
  
    
    if (token) {
      setIsLoggedIn(true);
      
    } else {
      setIsLoggedIn(false);
      navigate('/login');
    }
  }, []);

  return (
    <div className="d-flex">
      {/* Sidebar */}
      <div className="sidebar text-dark vh-100">       
        <h1 className="text-center pt-3x"><img src={ logo } width="135" style={{ marginLeft: '45px', marginRight: '75px' }} /></h1>
        <ul className="nav flex-column">
          <li className="nav-item pb-2 pt-2">
            <Link to="/individuals" className="nav-link text-dark"><i className="fa fa-users mr-1" style={{ marginRight: '5px' }}></i>Individuals</Link>
          </li>
          <li className="nav-item pb-2 pt-2">
            <Link to="/add-person" className="nav-link text-dark"><i className="fa fa-user-plus" style={{ marginRight: '5px' }}></i>Add Individual</Link>
          </li>
          <li className="nav-item pb-2 pt-2">
            <Link to="/list-roles" className="nav-link text-dark"><i className="fa fa-graduation-cap" style={{ marginRight: '5px' }}></i>Roles</Link>
          </li>
          <li className="nav-item pb-2 pt-2 border-bottom">
            <Link to="/add-role" className="nav-link text-dark"><i className="fa fa-graduation-cap" style={{ marginRight: '5px' }}></i>Add Role</Link>
          </li>  
          <li className="nav-item pb-2 pt-2">
            <Link to="/create-form" className="nav-link text-dark"> <FontAwesomeIcon icon={faCoffee} color="#BFA253" /> Create Form</Link>
          </li>
          <li className="nav-item pb-2 pt-2">
            <Link to="/forms" className="nav-link text-dark"><FontAwesomeIcon icon={faCoffee} color="#BFA253" /> View forms</Link>
          </li>
          <li className="nav-item pb-2 pt-2">
            <Link to="/organizations" className="nav-link text-dark"><FontAwesomeIcon icon={faPeopleRoof} color="#BFA253" /> Organisations</Link>
          </li>
          <li className="nav-item pb-2 pt-2">
            <Link to="/committees" className="nav-link text-dark"><FontAwesomeIcon icon={faPeopleRoof} color="#BFA253" /> Committees</Link>
          </li>
          <li className="nav-item pb-2 pt-2">
            <Link to="/delivery-partners" className="nav-link text-dark"><FontAwesomeIcon icon={faPeopleRoof} color="#BFA253" /> Delivery Partners</Link>
          </li>
          <li className="nav-item pb-2 pt-2 border-bottom">
            <Link to="/organisation" className="nav-link text-dark"><FontAwesomeIcon icon={faHouseMedicalFlag} color="#BFA253" /> Add Organisation</Link>
          </li>          
          {/* <li className="nav-item pb-2 pt-2">
            <Link to="#" className="nav-link text-dark"><FontAwesomeIcon icon={faRotate} color="#BFA253" /> Status Paris 2024</Link>
          </li> */}
          <li className="nav-item pb-2 pt-2 border-bottom">
            <Link to="#" className="nav-link text-dark"><FontAwesomeIcon icon={faBell} color="#BFA253" /> Changelogs</Link>
          </li>
          <li className="nav-item pb-2 pt-2 border-bottom">
            <Link to="#" className="nav-link text-dark"><FontAwesomeIcon icon={faDownload} color="#BFA253" /> Downloads</Link>
          </li>
          <li className="nav-item pb-2 pt-2 border-bottom">
            <Link to="#" className="nav-link text-dark"><i className="fa fa-envelope" style={{ marginRight: '5px' }}></i>Search</Link>
          </li>
          <li className="nav-item pb-2 pt-2 border-bottom">
            <Link to="#" className="nav-link text-dark"><FontAwesomeIcon icon={faCakeCandles} color="#BFA253" /> Birthdays (0)</Link>
          </li>
         
                 
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="content flex-grow-1 pe-4 pb-4">
        {/* Top Bar */}
        <nav className="navbar navbar-light justify-content-end p-4">
          <h4 className="navbar-brand">
            <div className="dropdown">
              <button
                className="btn btn-light dropdown-toggle"
                id="userDropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Welcome, {un || 'Guest'}
              </button>
              <ul className="dropdown-menu" aria-labelledby="userDropdown">
                <li>
                  <Link className="dropdown-item" to="/profile">
                    Profile
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/change-password">
                    Change Password
                  </Link>
                </li>
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </h4>
        </nav>

        {/* Outlet for content */}
        <div className="p-5 outlet-holder min-vh-100">
          <Outlet /> {/* Outlet for rendering components based on routes */}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
