import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from '../tableStyles';
import axios from 'axios';
import { useNavigate } from "react-router-dom";


function OrganisationList() {
  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(true);
  //const [orgTypes, setOrgTypes] = useState({});
  // const [countries, setCountries] = useState({});
   const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  // const specificIds = ['372', '374', '367', '368', '370', '377'];

  // Fetch organisation, orgType, and country data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgResponse = await axios.get(`${process.env.REACT_APP_API_URL}/organisations`);
        const orgTypeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/org-types`);
        const countryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);

        // Filter organisations by specific IDs
        const filteredOrganisations = orgResponse.data.filter(org => org.is_del_prtnr === true);

        // Map org types and countries by their IDs for quick lookup
        const orgTypesMap = orgTypeResponse.data.reduce((map, type) => {
          map[type.org_type_id] = type.org_type;
          return map;
        }, {});

        const countriesMap = countryResponse.data.reduce((map, country) => {
          map[country.country_id] = country.country_code;
          return map;
        }, {});

        setOrganisations(filteredOrganisations);
        // setOrgTypes(orgTypesMap);
        // setCountries(countriesMap);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter organisations based on search term
  const filteredOrganisations = organisations.filter(org => 
    org.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    org.short_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Define columns for DataTable
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },    
    {
      name: 'Logo',
      selector: row => row.logo,
      cell: row => (
        <img
          src={row.logo ? `/images/${row.logo}` : '/images/org_logo1.png'}
          alt={row.name}
          style={{ width: '50px', height: '50px', borderRadius: '4px' }}
        />
      ),
      width: '120px',
      ignoreRowClick: true,
      allowOverflow: true,
      sortable: false,
    },
    {  name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => navigate(`/organisation/${row._id}`, { state: { organisation: row } })}
        >
          {row.name}
        </button>
      ),
      sortable: true, },
    { name: 'Short Name', selector: row => row.short_name, sortable: true },
    { name: 'Org. Type', selector: row => row.org_type_id.org_type || 'Unknown', sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Phone', selector: row => row.phone1, sortable: true },
    { name: 'Country', selector: row => row.country_id.code || 'Unknown', sortable: true },
    { name: 'City', selector: row => row.city, sortable: true },
    { name: 'Active', selector: row => row.active ? 'Yes' : 'No', sortable: true },
  ];

  return (
    <div className="mt-5">
      <h2>Delivery Partners</h2>

      {/* Search input */}
      <input
        type="text"
        placeholder="Search by name or short name"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
      />

      <DataTable
        columns={columns}
        data={filteredOrganisations}
        progressPending={loading}
        pagination
        highlightOnHover
        striped
        responsive
        customStyles={customStyles} // Apply custom styles
      />
    </div>
  );
}

export default OrganisationList;
