import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { customStyles } from '../tableStyles';

function RoleList() {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([]);

  // Fetch role data from the server
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/roles`);
        setRoles(response.data);
        setFilteredRoles(response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoles();
  }, []);

  // Search handler
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = roles.filter(role =>
      role.role_name.toLowerCase().includes(value) ||
      (role.role_type || '').toLowerCase().includes(value) // Handle potential null role_type
    );
    setFilteredRoles(filtered);
  };

  // Define columns for DataTable
  const columns = [
    {
      name: '#',
      cell: (row, index) => index + 1,
      width: '60px',
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    { name: 'Role Name', selector: row => row.role_name, sortable: true },
    { name: 'Role Type', selector: row => row.role_type || 'N/A', sortable: true }, // Display 'N/A' if role_type is null
    { name: 'Role ID', selector: row => row._id, sortable: true }, // Use `_id` for MongoDB
  ];

  return (
    <div className="mt-2">
      <h2>Roles</h2>

      {/* Search input */}
      <input
        type="text"
        placeholder="Search by role name or type"
        className='form-control'
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
      />

      <DataTable
        columns={columns}
        data={filteredRoles}
        progressPending={loading}
        pagination
        highlightOnHover
        striped
        responsive
        customStyles={customStyles} // Apply custom styles
      />
    </div>
  );
}

export default RoleList;
