export const customStyles = {
  tableWrapper: {
    style: {
      backgroundColor: "white",
      padding: "20px",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",      
    },
  },
  header: {
    style: {
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#333',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px 8px 0 0', // Rounded corners for the top-left and top-right      
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      '&:first-child': {
        borderTopLeftRadius: '8px', // Top-left corner
        borderBottomLeftRadius: '8px', // Bottom-left corner
      },
      '&:last-child': {
        borderTopRightRadius: '8px', // Top-right corner
        borderBottomRightRadius: '8px', // Bottom-right corner
      },      
      color: '#ffffff', 
      backgroundColor: '#BFA253',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
      color: '#333',
      backgroundColor: '#F8F5F0', // Light cream color for rows
      padding: '10px',
      '&:first-child': {
        borderTopLeftRadius: '8px', // Top-left corner
        borderBottomLeftRadius: '8px', // Bottom-left corner
      },
      '&:last-child': {
        borderTopRightRadius: '8px', // Top-right corner
        borderBottomRightRadius: '8px', // Bottom-right corner
      },      
    },
  },
  rows: {
    style: {
      minHeight: '72px', // Row height
      margin: '10px 0', // Add spacing between rows        
      borderRadius: '8px', // Add rounded corners to rows
      backgroundColor: '#F8F5F0', // Optional row background       
    },
  },
  pagination: {
    style: {
      color: '#333',
      fontSize: '14px',
      backgroundColor: '#fff',
      borderBottomRightRadius: '8px', // Bottom-right corner
      borderBottomLeftRadius: '8px', // Bottom-left corner
    },
  },
};
