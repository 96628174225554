import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { customStyles } from "../tableStyles";

function OrganisationList() {
  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [orgTypes, setOrgTypes] = useState({});
  // const [countries, setCountries] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgResponse = await axios.get(`${process.env.REACT_APP_API_URL}/organisations`);
        // const orgTypeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/org-types`);
        // const countryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);

        // const orgTypesMap = orgTypeResponse.data.reduce((map, type) => {
        //   map[type._id] = type.org_type;
        //   return map;
        // }, {});

        // const countriesMap = countryResponse.data.reduce((map, country) => {
        //   map[country._id] = country.code;
        //   return map;
        // }, {});

        setOrganisations(orgResponse.data);
        // setOrgTypes(orgTypesMap);
        // setCountries(countriesMap);       
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredOrganisations = organisations.filter((org) =>
    org.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Logo",
      selector: (row) => row.logo,
      cell: (row) => (
        <img
          src={row.logo ? `/images/${row.logo}` : "/images/org_logo1.png"}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "4px" }}
        />
      ),
      width: "120px",
      ignoreRowClick: true,
      allowOverflow: true,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => navigate(`/organisation/${row._id}`, { state: { organisation: row } })}
        >
          {row.name}
        </button>
      ),
      sortable: true,
    },
    { name: "Org. Type", selector: (row) => row.org_type_id.org_type || "unknown", sortable: true },
    { name: "Email", selector: (row) => row.email, sortable: true },
    { name: "Country Code", selector: (row) => row.country_id.code || "Unknown", sortable: true },
    { name: "Active", selector: (row) => (row.active ? "Yes" : "No"), sortable: true },
    
  ];

  return (
    <div className="mt-2">     
      <h2>Organisations</h2>
      <div className='mt-2 p-3 bg-white rounded'>
      <div className='row'>
      <div className='col-md-4'>
          <input
            type="text"
            placeholder="Search by name"
            className="form-control py-3"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
          />
        </div>
      </div>     
      <DataTable
        columns={columns}
        data={filteredOrganisations}
        progressPending={loading}
        pagination
        highlightOnHover
        striped
        responsive
        customStyles={customStyles}
      />
    </div>
    </div>
  );
}

export default OrganisationList;
