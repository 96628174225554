import React, { useState } from 'react';
import axios from 'axios';

const AddRole = () => {
  const [roleName, setRoleName] = useState('');
  const [roleType, setRoleType] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/roles/`, {
        role_name: roleName,
        role_type: roleType,
      });

      setMessage('Role added successfully!');
    } catch (error) {
      console.error('Error adding role:', error);
      setMessage(error.response?.data?.message || 'Error adding role');
    }

    // Clear the form
    setRoleName('');
    setRoleType('');
  };

  return (
    <div className="d-flex vh-100">
      <div className="card p-4 shadow-lg" style={{ width: '100%' }}>
        <h2>Add New Role</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group row mb-2">
            <label htmlFor="roleName" className="col-sm-3 col-form-label">
              Role Name:
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                id="roleName"
                className="form-control"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <label htmlFor="roleType" className="col-sm-3 col-form-label">
              Role Type:
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                id="roleType"
                className="form-control"
                value={roleType}
                onChange={(e) => setRoleType(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-10">
              <button type="submit" className="btn btn-primary">
                Add Role
              </button>
            </div>
          </div>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default AddRole;
