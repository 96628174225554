// src/components/FormBuilderView.js
import React, { useState } from 'react';
import { FormBuilder } from 'react-formio';
import { saveFormToLocal } from '../utils/formUtils';

const FormBuilderView = () => {
  const [formSchema, setFormSchema] = useState({});

  const handleFormChange = (schema) => {
    setFormSchema(schema);
  };

  const handleSave = () => {
    saveFormToLocal(formSchema);
    alert('Form saved successfully!');
  };

  return (
    <div className="row">
      <h2>Form Builder</h2>
      <div className="mt-2 p-3 bg-white rounded min-vh-100">
        <div className="row">
      <FormBuilder form={formSchema} onChange={handleFormChange} />
      <div className="row">
      <div className='col-md-2'>  
      <button className="btn btn-warning mt-3 btn-md" onClick={handleSave}>
        Save Form
      </button>
      </div>
      </div>
    </div>
    </div>
    </div>
    
  );
};

export default FormBuilderView;
