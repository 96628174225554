import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

const AddEditPersonForm = ({ organisations = [], roles = [], relations = [] }) => {
  const { id } = useParams(); // Get personId from the URL 
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    name: "",
    mname: "",
    lname: "",
    preferred_name: "",
    date_of_birth: "",
    birth_city: "",
    birth_country: "",
    marital_status: "",
    deceased_date: "",
    email: "",
    email2: "",
    password: "",
    country_id: "",
    gender: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phone1: "",
    phone2: "",
    fax: "",
    website: "",
    facebook: "",
    twitter: "",
    instagram: "",
    other_social_media: "",
    languages: "",
    comments: "",
    web_desc: "",
    image: "",
    status: true,
    xmas1: false,
    xmas2: false,
  });

  const [personRoles, setPersonRoles] = useState([]);
  const [newRole, setNewRole] = useState({
    organisation_id: "",
    role_id: "",
    start_date: "",
    end_date: "",
    main_role: false,
  });
  const [profileImage, setProfileImage] = useState(); 
  
const [relationships, setRelationships] = useState([]); // List of relationships
const [assistants, setAssistants] = useState([]); // List of available assistants
const [relationshipTypes, setRelationshipTypes] = useState([]); // List of relationship types
const [newRelationship, setNewRelationship] = useState({
  assistant_id: "",
  rel_id: "",
}); // New relationship
  const [countries, setCountries] = useState([]);
  const [orgns, setOrganisations] = useState([]);
  const [rolenames, setRolenames] = useState([]);
  const [continentCode, setContinentCode] = useState("");
  const [activeTab, setActiveTab] = useState("Profile");
  const [loading, setLoading] = useState(false);

  // Fetch person and roles data when personId changes
  useEffect(() => {
    //if (!id) return; // Add mode; no need to fetch data
    setLoading(true);

    const fetchData = async () => {
      try {
        if (id)
        {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/persons/${id}`);             
        setFormData(data.person); // Populate person data
        setProfileImage(data.person.image ? `/images/${data.person.image}` : "/images/avatar.png");
        setPersonRoles(data.roles); // Populate roles data
        setRelationships(data.relationships); // Populate relationships
        console.log(data.relationships);
        }
        const orgResponse = await axios.get(`${process.env.REACT_APP_API_URL}/organisations`);  
        const roleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/roles`); 
        const orgMap = orgResponse.data.map(({ _id, name }) => ({ _id, name }));
        const roleMap = roleResponse.data.map(({_id, role_name}) => ({_id, role_name}));
        const assistantResponse = await axios.get(`${process.env.REACT_APP_API_URL}/persons`);
        const filteredAssistants = assistantResponse.data.filter(
          (assistant) => assistant._id !== id
        );
        setAssistants(filteredAssistants);
        // Fetch relationship types
        const relTypeResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/relationship-types`
        );       
        setRelationshipTypes(relTypeResponse.data);
        setOrganisations(orgMap);
        setRolenames(roleMap)
      } catch (error) {
        console.error("Error fetching person data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Fetch countries for dropdown
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);
        setCountries(response.data);

        if (formData.country_id) {
          const selectedCountry = response.data.find(
            (country) => country._id === formData.country_id
          );
          if (selectedCountry) {
            setContinentCode(selectedCountry.continent);
          }
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [formData.country_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "country_id") {
      const selectedCountry = countries.find((country) => country._id === value);
      setContinentCode(selectedCountry ? selectedCountry.continent : "");
    }
  };

  const handleRoleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRole((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddRole = () => {
    if (!newRole.role_id || !newRole.organisation_id) {
      alert("Please select both organization and role.");
      return;
    }
    setPersonRoles((prev) => [...prev, { ...newRole, id: Date.now() }]);
    setNewRole({
      organisation_id: "",
      role_id: "",
      start_date: "",
      end_date: "",
      main_role: false,
    });
  };

  const handleRemoveRole = (id) => {
    setPersonRoles((prev) => prev.filter((role) => role._id !== id));
  };
  
  const [selectedFile, setSelectedFile] = useState(null);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => setProfileImage(reader.result);
    reader.readAsDataURL(file);
  };

  const handleRelationshipInputChange = (e) => {
    const { name, value } = e.target;
    setNewRelationship((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddRelationship = () => {
    if (!newRelationship.assistant_id || !newRelationship.rel_id) {
      alert("Please select both an assistant and a relationship type.");
      return;
    }
    setRelationships((prev) => [
      ...prev,
      { ...newRelationship, id: Date.now() }, // Temporary ID for client-side
    ]);
    setNewRelationship({
      assistant_id: "",
      rel_id: "",
    });
  };
  
  const handleRemoveRelationship = (id) => {
    setRelationships((prev) => prev.filter((rel) => rel._id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(relationships);
      const updatedData = { ...formData, roles: personRoles, relationships:relationships };
      if (id) {
        console.log(updatedData);
        await axios.put(`${process.env.REACT_APP_API_URL}/persons/${id}`, updatedData);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/persons`, updatedData);
      }
      // navigate("/");
    } catch (error) {
      console.error("Error saving person:", error);
    }
  };
  
  const handleEditorChange = (content) => {
    setFormData((prev) => ({
      ...prev,
      web_desc: content, // Bind editor content to web_desc
    }));
  };

  const renderProfileTab = () => (
    <div>
      <div className="row mt-3">
        <div className="form-group col-md-2">
          <label>Title</label>
          <select
            className="form-control py-3"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
          >
           <option value="">Title</option>
           <option value="Mr.">Mr.</option>
           <option value="Mrs.">Mrs.</option>
           <option value="Ms.">Ms.</option>
           <option value="Dr.">Dr.</option>
           <option value="Prof.">Prof.</option>
          </select>
        </div>
        <div className="form-group col-md-2">
          <label>Gender</label>
          <select
            className="form-control py-3"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      <div className="row mt-3">
        <div className="form-group col-md-3">
          <label>First Name</label>
          <input
            type="text"
            className="form-control py-3"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Middle Name</label>
          <input
            type="text"
            className="form-control py-3"
            name="mname"
            value={formData.mname}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Last Name</label>
          <input
            type="text"
            className="form-control py-3"
            name="lname"
            value={formData.lname}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Preferred Name</label>
          <input
            type="text"
            className="form-control py-3"
            name="preferred_name"
            value={formData.preferred_name}
            onChange={handleInputChange}
          />
        </div>
      </div>
     
    </div>
  );

  const renderAddressTab = () => (
    <div>
      <h4>Address Details</h4>
      <div className="row mt-3">
      <div className="form-group col-md-3">
          <label>Landline</label>
          <input
            type="text"
            className="form-control py-3"
            name="phone1"
            value={formData.phone1}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Mobile Phone</label>
          <input
            type="text"
            className="form-control py-3"
            name="phone2"
            value={formData.phone2}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Email 1</label>
          <input
            type="email"
            className="form-control py-3"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Email 2</label>
          <input
            type="email"
            className="form-control py-3"
            name="email2"
            value={formData.email2}
            onChange={handleInputChange}
          />
        </div>
        
      </div>
      <div className="row mt-3">
      <div className="form-group col-md-3">
          <label>Address Line 1</label>
          <input
            type="text"
            className="form-control py-3"
            name="address1"
            value={formData.address1}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Address Line 2</label>
          <input
            type="text"
            className="form-control py-3"
            name="address2"
            value={formData.address2}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Fax</label>
          <input
            type="text"
            className="form-control py-3"
            name="fax"
            value={formData.fax}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Continent</label>
           <input
            type="text"
            className="form-control py-3"
            value={continentCode}
            readOnly
            style={{ color: "gray", backgroundColor: "#f9f9f9" }}
          />
        </div>
        </div>
        <div className="row mt-3">
        <div className="form-group col-md-3">
          <label>Zip Code</label>
          <input
            type="text"
            className="form-control py-3"
            name="zipcode"
            value={formData.zipcode}
            onChange={handleInputChange}
          />
        </div>   
        <div className="form-group col-md-3">
          <label>City</label>
          <input
            type="text"
            className="form-control py-3"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>State</label>
          <input
            type="text"
            className="form-control py-3"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Country</label>
          <select
            className="form-control py-3"
            name="country_id"
            value={formData.country_id}
            onChange={handleInputChange}
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country._id} value={country._id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>        
      </div>
    </div>
  );

  const renderPersonalTab = () => (
    <div>
      <h4>Personal Details</h4>
      <div className="row mt-3">
      <div className="form-group col-md-3">
          <label>Date of Birth</label>
          <input
            type="date"
            className="form-control py-3"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleInputChange}
          />
        </div>
        
        <div className="form-group col-md-3">
          <label>Deseased Date</label>
          <input
            type="date"
            className="form-control py-3"
            name="deceased_date"
            value={formData.deceased_date}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>City of Birth</label>
          <input
            type="text"
            className="form-control py-3"
            name="birth_city"
            value={formData.birth_city}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Country of Birth</label>          
           <select
            className="form-control py-3"
            name="birth_country"
            value={formData.birth_country}
            onChange={handleInputChange}
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country._id} value={country._id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-3">
      <div className="form-group col-md-3">
          <label>Marital Status</label>
          <select
            className="form-control py-3"
            name="marital_status"
            value={formData.marital_status}
            onChange={handleInputChange}
          >
           <option value="">Marital Status</option>
           <option value="Single">Single</option>
           <option value="Married">Married</option>
           <option value="Widowed">Widowed</option>
           <option value="Separated">Separated</option>
           <option value="Divorced">Divorced</option>           
          </select>
        </div>
        <div className="form-group col-md-3">
        <label>Website</label>
        <input
          type="text"
          className="form-control py-3"
          name="website"
          value={formData.website}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group col-md-3">
          <label>Facebook</label>
          <input
            type="text"
            className="form-control py-3"
            name="facebook"
            value={formData.facebook}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Twitter</label>
          <input
            type="text"
            className="form-control py-3"
            name="twitter"
            value={formData.twitter}
            onChange={handleInputChange}
          />
        </div>
        
      </div>
      <div className="row mt-3">
      <div className="form-group col-md-3">
          <label>Instagram</label>
          <input
            type="text"
            className="form-control py-3"
            name="instagram"
            value={formData.instagram}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Other Social Media</label>
          <input
            type="text"
            className="form-control py-3"
            name="other_social_media"
            value={formData.other_social_media}
            onChange={handleInputChange}
          />
        </div>
      </div>
     
    </div>
  );

  const renderOtherTab = () => (
    <div>
      <h4>Other Details</h4>     
      <div className="row mt-3">
      <div className="form-group col-md-3">
        <label>Languages</label>
        <textarea          
          className="form-control py-3"
          name="languages"
          rows={10} 
          value={formData.languages}
          onChange={handleInputChange}
        />
      </div> 
      <div className="form-group col-md-3">
        <label>Comments</label>
        <textarea
          className="form-control py-3"
          name="comments"
          value={formData.comments}
          rows={10}         
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group col-md-6">
        <label>Web Description</label>
        <Editor
            apiKey="zk2zzdzsc5ofia3cqt9boi8numoj5fr2y4lj21p6lstv9t9t"
            value={formData.web_desc}
            init={{
              height: 246,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleEditorChange}
          />       
      </div>
    </div>
    </div>
  );

  const renderRolesTab = () => (
    <div>
      <h4>Roles</h4>
      <table className="table">
        <thead >
          <tr>
            <th className="bg-warning text-white">Organization</th>
            <th className="bg-warning text-white">Role</th>
            <th className="bg-warning text-white">Start Date</th>
            <th className="bg-warning text-white">End Date</th>
            <th className="bg-warning text-white">Main Role</th>
            <th className="bg-warning text-white">Actions</th>
          </tr>
        </thead>
        <tbody>
          {personRoles.map((role, index) => ( console.log(role),
            <tr key={index}>
              <td>
              {orgns.find((org) => org._id === role.organisation_id) ? (
                <button
                  type="button"
                  className="btn btn-link p-0 text-decoration-none"
                  onClick={() =>
                    navigate(`/organisation/${role.organisation_id}`, {
                      state: {
                        organisation: orgns.find(
                          (org) => org._id === role.organisation_id
                        ),
                      },
                    })
                  }
                >
                  {
                    orgns.find((org) => org._id === role.organisation_id)
                      ?.name
                  }
                </button>
              ) : (
                "N/A"
              )}
              </td>
              <td>{rolenames.find((r) => r._id === role.role_id)?.role_name || "N/A"}</td>
              <td>{role.start_date || "N/A"}</td>
              <td>{role.end_date || "N/A" }</td>
              <td>{role.main_role ? "Yes" : "No"}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemoveRole(role._id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h5>Add New Role</h5>
      <div className="row m-0">
        <div className="col-md-12 py-3 bg-white">
        <div className="row my-3">
        <div className="form-group col-md-3">
          <label>Organization</label>
          <select
            className="form-control py-3"
            name="organisation_id"
            value={newRole.organisation_id}
            onChange={handleRoleInputChange}
          >
            <option value="">Select Organization</option>
            {orgns.map((org) => (
              <option key={org._id} value={org._id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-2">
          <label>Role</label>
          <select
            className="form-control py-3"
            name="role_id"
            value={newRole.role_id}
            onChange={handleRoleInputChange}
          >
            <option value="">Select Role</option>
            {rolenames.map((role) => (
              <option key={role._id} value={role._id}>
                {role.role_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-2">
          <label>Start Date</label>
          <input
            type="date"
            className="form-control py-3"
            name="start_date"
            value={newRole.start_date}
            onChange={handleRoleInputChange}
          />
        </div>
        <div className="form-group col-md-2">
          <label>End Date</label>
          <input
            type="date"
            className="form-control py-3"
            name="end_date"
            value={newRole.end_date}
            onChange={handleRoleInputChange}
          />
        </div>
        <div className="form-group col-md-3">
        <input
          type="checkbox"
          className="form-check-input me-2 mt-4"
          id="mainRole"
          name="main_role"
          checked={newRole.main_role}
          onChange={(e) =>
            setNewRole((prev) => ({
              ...prev,
              main_role: e.target.checked,
            }))
          }
        />
        <label className="form-check-label mt-4" htmlFor="mainRole">
          Main Role
        </label>
        <button type="button" className="btn btn-success mt-3 float-end" onClick={handleAddRole}>
        Add Role
      </button>
        </div>
        </div>
      </div>      
    </div>
    </div>
  );

  const renderRelationshipsTab = () => (
    <div>
      <h4>Relationships</h4>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Relation</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {relationships.map((rel, index) => ( console.log(rel),
            <tr key={index}>
              <td>{assistants.find((a) => a._id === rel.assistant_id)?.name || "N/A"}</td>
              <td>
                {relationshipTypes.find((rt) => rt._id === rel.rel_id)?.rel_name || "N/A"}
              </td>
              <td>
              {assistants.find((a) => a._id === rel.assistant_id)?.email || "N/A"}
              </td>
              <td>
              {assistants.find((a) => a._id === rel.assistant_id)?.phone2 || assistants.find((a) => a._id === rel.assistant_id)?.phone1 || "N/A"}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemoveRelationship(rel._id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  
      <h5>Add New Relationship</h5>
      <div className="row m-0">
      <div className="col-md-12 py-3 bg-white">
      <div className="row my-3">
        <div className="form-group col-md-4">
          <label>Person</label>
          <select
            className="form-control py-3"
            name="assistant_id"
            value={newRelationship.assistant_id}
            onChange={(e) =>
              setNewRelationship((prev) => ({
                ...prev,
                assistant_id: e.target.value,
              }))
            }
          >
            <option value="">Select Assistant</option>
            {assistants.map((assistant) => (
              <option key={assistant._id} value={assistant._id}>
                {assistant.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-4">
          <label>Relationship Type</label>
          <select
            className="form-control py-3"
            name="rel_id"
            value={newRelationship.rel_id}
            onChange={(e) =>
              setNewRelationship((prev) => ({
                ...prev,
                rel_id: e.target.value,
              }))
            }
          >
            <option value="">Select Relationship Type</option>
            {relationshipTypes.map((relType) => (
              <option key={relType._id} value={relType._id}>
                {relType.rel_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-4">
        <button
        type="button"
        className="btn btn-success mt-3 float-end"
        onClick={handleAddRelationship}
      >
        Add Relationship
      </button>
          </div>
      </div>
     
    </div>
    </div>
    </div>
  );
  

  return loading ? (
    <div>Loading...</div>
  ) :  (
    <div className="mt-4">
        <div className="row mb-4">
            <div className="col-md-2 col-sm-3">
            <img src={profileImage} alt="Profile" className="rounded-circle" width="120" />
        <div>
          <button
            type="button"
            className="btn btn-secondary mt-2"
            onClick={() => document.getElementById("profileImageInput").click()}
          >
            Upload Profile Photo
          </button>
          <input
            type="file"
            id="profileImageInput"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
            </div>         
         <div className="col-sm-9 col-md-10 d-flex align-self-center justify-content-center"> <h1>{formData.name || formData.mname || formData.lname
      ? `${formData.name || ""} ${formData.mname || ""} ${formData.lname || ""}`.trim()
      : "Add/Edit Person"}</h1></div>
        </div>
        <div className="row border border-white p-3 rounded"> 
         <div className="tabs">
        {["Profile", "Address", "Personal", "Other", "Roles", "Relationships"].map((tab) => (
          <button
            key={tab}
            className={`btn px-5 py-3 mx-2 ${activeTab === tab ? "btn-warning" : "btn-success"}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        {activeTab === "Profile" && renderProfileTab()}
        {activeTab === "Address" && renderAddressTab()}
        {activeTab === "Personal" && renderPersonalTab()}
        {activeTab === "Other" && renderOtherTab()}
        {activeTab === "Roles" && renderRolesTab()}
        {activeTab === "Relationships" && renderRelationshipsTab()}

        <button type="submit" className="btn btn-success mt-4 p-3">
          Save
        </button>
        <button type="button" className="btn btn-secondary mt-4 ms-2 p-3" onClick={() => navigate("/")}>
          Cancel
        </button>
      </form>
    </div>
    </div> 
  );
};

export default AddEditPersonForm;
